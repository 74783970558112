import { get as getV2, update as updateV2 } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";

export const getProgramsFromV2 = (programId, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.PROGRAMS.path,
      ressources: [
        {
          name: programId,
          value: ""
        }
      ]
    },
    {},
    axios
  );

export const getConfigurationV2 = (domainId, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.DOMAINS.path,
      ressources: [
        {
          name: domainId
        }
      ]
    },
    {},
    axios
  );

export const updateDomainV2 = (domainId, body, axios) =>
  updateV2(
    {
      path: ENTITY_NAME_IN_URI_V2.DOMAINS.path,
      ressources: [
        {
          name: domainId
        }
      ]
    },
    body,
    axios
  );
