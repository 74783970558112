import { BToast } from "bootstrap-vue";
//import Toasted from 'vue-toasted'
/**
 * Service to handle errors
 */
const ErrorService = {
  getErrorMsg(error) {
    return error.response && error.response.data && error.response.data.errors
      ? Array.isArray(error.response.data.errors)
        ? error.response.data.errors.join("</br>")
        : error.response.data.errors
      : "";
  },
  displayErrorMsg(error) {
    const msg = this.getErrorMsg(error);
    const bootStrapToaster = new BToast();
    bootStrapToaster.$bvToast.toast(msg, {
      title: "Error",
      toaster: "b-toaster-bottom-right",
      solid: true,
      variant: "danger",
      appendToast: false,
      autoHideDelay: 5000
    });
  }
};

export default ErrorService;
